<template>
  <div
    :style="cssProps"
    class="full-size-container h-full col-12"
  >
    <h2
      v-if="!hasActivePackage"
      class="d-flex justify-center text-center main-title"
    >
      {{ $t("product-packages.main-title") }}<br>
    </h2>

    <div
      v-if="isMobile"
    >
      <Currencies
        :currency-symbol="currencySymbol"
        @updateCurrencySymbol="updateCurrencySymbol"
      />
    </div>
    <div class="pa-0 mx-auto package-selection-tabs-wrapper">
      <div class="d-flex justify-center text-center ">
        <div
          class="package-selection-tabs d-flex justify-space-between align-center"
          :class="{'col-12': isMobile}"
        >
          <div
            class="pay-monthly mr-sm-10 py-2 pointer"
            :class="{ 'selected': payingPeriod === 'monthly', 'col-6': isMobile }"
            @click="setMonthlyPayingPeriod()"
          >
            <h4>{{ $t("product-packages.pay-monthly") }}</h4>
          </div>
          <div
            class="pay-annually py-2 pointer"
            :class="{ 'selected': payingPeriod === 'yearly', 'col-6': isMobile }"
            @click="setYearlyPayingPeriod()"
          >
            <h4>{{ $t("product-packages.pay-annually") }} - <span>{{ $t("product-packages.save") }} ~20%</span></h4>
          </div>
        </div>
      </div>
      <div
        v-if="!isMobile"
        class="d-flex justify-end currency-wrapper"
      >
        <Currencies
          :currency-symbol="currencySymbol"
          @updateCurrencySymbol="updateCurrencySymbol"
        />
      </div>
    </div>

    <div
      v-if="isMobile"
      class="packages-tabs d-flex justify-center py-1"
      :style="{ 'margin-bottom': activeTab === TabName.BEGINNER ? '30px' : '80px'}"
    >
      <div
        class="package-tab pointer mr-5 align-center text-center"
        :class="{ 'active-tab-basic': activeTab === TabName.BEGINNER }"
        @click="activeTab = TabName.BEGINNER"
      >
        <div
          class="step-number mx-auto"
          :class="{'active': activeTab === TabName.BEGINNER}"
        >
          <span class="my-auto">
            01
          </span>
        </div>
        <h5>{{ $t("product-packages.basic-package.title") }}</h5>
      </div>
      <div
        class="package-tab pointer align-center text-center"
        :class="{ 'active-tab-advanced': activeTab === TabName.ADVANCED }"
        @click="activeTab = TabName.ADVANCED"
      >
        <div
          class="step-number mx-auto"
          :class="{'active': activeTab === TabName.ADVANCED}"
        >
          <span class="my-auto">
            02
          </span>
        </div>
        <h5 class=" px-0">
          {{ $t("product-packages.advanced-package.title") }}
        </h5>
      </div>
    </div>

    <div
      v-if="isMobile"
      class="col-12 pa-0"
    >
      <component
        :is="getPackageTabComponent()"
        :fallback-country-code="fallbackCountryCode"
        :currency-symbol="currencySymbol"
        :paying-period="payingPeriod"
        :has-active-package="hasActivePackage"
        :active-package="activePackage"
        :is-basic-package-active="isBasicPackageActive"
        :is-advanced-package-active="isAdvancedPackageActive"
        :product-type="getProductType()"
        @navigateToBasicPackage="navigateToBasicPackage"
      />
    </div>

    <div
      v-if="!isMobile"
      class="pt-0 mx-auto d-flex justify-center packages-wrapper"
    >
      <BasicPackage
        :currency-symbol="currencySymbol"
        :fallback-country-code="fallbackCountryCode"
        :paying-period="payingPeriod"
        :has-active-package="hasActivePackage"
        :active-package="activePackage"
        :is-basic-package-active="isBasicPackageActive"
        :product-type="ProductType.BEGINNER"
      />
      <div>
        <AdvancedPackage
          :fallback-country-code="fallbackCountryCode"
          :currency-symbol="currencySymbol"
          :paying-period="payingPeriod"
          :has-active-package="hasActivePackage"
          :active-package="activePackage"
          :is-basic-package-active="isBasicPackageActive"
          :is-advanced-package-active="isAdvancedPackageActive"
          :product-type="ProductType.ADVANCED"
        />
      </div>
    </div>

    <Info
      v-if="packageUpdating"
      :is-loading="loading"
      :error-message="errorMessage"
      :updated-package-type="updatedPackageType"
      @close="packageUpdating = false"
    />
  </div>
</template>
<script>
import AdvancedPackage from './packages/advanced-package.vue'
import BasicPackage from './packages/basic-package.vue'
import { PayPeriod } from './enums/PayPeriod'
import { CurrencySymbol } from './enums/CurrencySymbol'
import { TabName } from './enums/TabName'
import ProductFinder from '@/components/product-finder/ProductFinder.vue'
import { Countries } from '@/mixins/countries'
import Currencies from './Currencies.vue'
import { ProductType } from './enums/ProductType'
import Info from './info/Info.vue'

export default {
  components: { Currencies, ProductFinder, BasicPackage, AdvancedPackage, Info },
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeTab: '',
      payingPeriod: PayPeriod.ANNUALLY,
      currenciesSymbol: [
        CurrencySymbol.USDOLLAR,
        CurrencySymbol.EURO,
        CurrencySymbol.SWISSFRANC
      ],
      currencySymbol: CurrencySymbol.USDOLLAR,
      fallbackCountryCode: null,
      redirectPageTimeout: null,
      loading: true,
      packageUpdating: false,
      updatedPackageType: null,
      errorMessage: ''
    }
  },
  computed: {
    cssProps () {
      return {
        '--blue-color': this.$vuetify.theme.themes.light.markero.blue,
        '--light-orange': this.$vuetify.theme.themes.light.markero.lightOrange
      }
    },
    ProductType () {
      return ProductType
    },
    productType () {
      return this.company?.productType
    },
    isBasicPackageActive () {
      return this.productType === ProductType.BEGINNER
    },
    isAdvancedPackageActive () {
      return this.productType === ProductType.ADVANCED
    },
    hasActivePackage () {
      return !!this.productType
    },
    activePackage () {
      if (!this.hasActivePackage) return null

      return this.productType
    },
    hasQueries () {
      return Object.keys(this.$route.query).length > 0
    },
    TabName () {
      return TabName
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isYearlyPaying () {
      return this.payingPeriod === PayPeriod.ANNUALLY
    }
  },
  watch: {
    '$route' () {
      this.$router.go(0)
    }
  },
  async mounted () {
    if (this.hasQueries) {
      await this.setCurrencySymbol()
      this.setPayingPeriod()
      if (this.isMobile) { this.setActiveTab() }
      return
    }

    await this.fetchBrowserGeolocation()
    if (this.isMobile) { this.setActiveTab() }
  },
  unmounted () {
    if (this.redirectPageTimeout) {
      clearTimeout(this.redirectPageTimeout)
    }
  },
  methods: {
    setMonthlyPayingPeriod () {
      this.payingPeriod = 'monthly'
      this.$tracking.event('Plan Selection', this.$tracking.trackingEvents.CLICKED, 'pay monthly')
    },
    setYearlyPayingPeriod () {
      this.payingPeriod = 'yearly'
      this.$tracking.event('Plan Selection', this.$tracking.trackingEvents.CLICKED, 'pay yearly')
    },
    getProductType () {
      return ({
        [this.TabName.BEGINNER]: ProductType.BEGINNER,
        [this.TabName.ADVANCED]: ProductType.ADVANCED
      })[this.activeTab]
    },
    getPackageTabComponent () {
      return ({
        [this.TabName.BEGINNER]: 'BasicPackage',
        [this.TabName.ADVANCED]: 'AdvancedPackage'
      })[this.activeTab]
    },
    setPayingPeriod () {
      this.payingPeriod = this.$route.query?.isYearly ? PayPeriod?.ANNUALLY : PayPeriod?.MONTHLY
    },
    async fetchBrowserGeolocation () {
      try {
        // I'm searching for better solution to get the country code
        this.fallbackCountryCode = 'DE'
        this.getFallbackCurrencySymbol()
      } catch (error) {
        this.updateCurrencySymbol(CurrencySymbol.USDOLLAR)
      }
    },
    updateCurrencySymbol (symbol) {
      this.$tracking.event('Plan Selection', this.$tracking.trackingEvents.CLICKED, 'Change currency selected')
      this.currencySymbol = symbol
    },
    navigateToProductFinder () {
      this.$router.push({ name: 'product-finder' })
    },
    navigateToBasicPackage () {
      this.activeTab = TabName.BEGINNER
    },
    async setCurrencySymbol () {
      const currencySymbol = this.$route.query?.currencySymbol
      if (currencySymbol) {
        this.updateCurrencySymbol(currencySymbol)
      } else {
        await this.fetchBrowserGeolocation()
      }
    },
    getFallbackCurrencySymbol () {
      const country = Countries.find(country => country.countryCode === this.fallbackCountryCode)
      this.updateCurrencySymbol(country?.currencySymbol ?? CurrencySymbol.USDOLLAR)
    },
    setActiveTab () {
      this.activeTab = TabName.BEGINNER
    }
  }
}
</script>
<style scoped>
/* General Styles */
body, h4, h5, span, .main-title {
  font-family: 'Poppins', sans-serif;
}

/* Wrapper Styles */
.packages-wrapper {
  width: max-content;
  flex-shrink: 0;
  border-radius: 20px;
  background: white;
  padding: 0 40px 30px 0;
  box-shadow: 20px 20px 180px 20px rgba(48, 34, 147, 0.10);
}

/* Title Styles */
.main-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 40px;
}

/* Tabs Styles */
.package-selection-tabs-wrapper {
  width: 830px;
}

.package-selection-tabs {
  padding: 6px 12px;
  max-width: 100%;
  flex-shrink: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 40px 0 rgba(34, 21, 130, 0.10);
}

.packages-tabs {
  border-radius: 10px;
  box-shadow: 10px 10px 40px 0 rgba(34, 21, 130, 0.10);
}

.package-tab {
  width: 161.5px;
  height: 60px;
  flex-shrink: 0;
}

.package-tab h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
}

.package-tab .active {
  color: var(--light-orange);
  background-color: white;
  border: none;
}

.active-tab-basic, .active-tab-advanced {
  background-color: var(--light-orange);
  border-radius: 10px;
  color: white;
}

.active-tab-basic h5, .active-tab-advanced h5 {
  font-weight: 700;
}

/* Payment Option Styles */
.pay-monthly h4, .pay-annually h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 0 10px;
}

.pay-annually h4 span {
  font-weight: 700;
  color: var(--blue-color);
}

.selected.pay-annually h4 span, .selected {
  color: white;
}

.selected.pay-monthly h4, .selected.pay-annually h4 {
  font-weight: 700;
}

.selected {
  background-color: var(--blue-color);
  padding: 0 10px;
  border-radius: 10px;
  flex-shrink: 0;
}

/* Step Number Styles */
.step-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 7px;
  border: 2px solid #E4E4E4;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-number span {
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
}

/* Miscellaneous Styles */
.pointer {
  cursor: pointer;
}

.currency-wrapper {
  border-radius: 10px;
  margin: -47px 0 90px;
}

/* Vuetify Specific Overrides */
::v-deep .currency .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 35px;
}

::v-deep .v-autocomplete.v-select.v-input--is-focused input {
  min-width: 30px;
}

::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px;
}

::v-deep .currency .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 44px !important;
}

@media only screen and (max-width: 960px) {
  .pay-monthly, .pay-annually {
    width: 100%;
  }
  .package-selection-tabs-wrapper {
    width: 100%;
  }
  .package-tab {
    width: 46%;
  }
  .main-title {
    margin-bottom: 0;
  }
  .packages-tabs {
    margin-top: 10px;
  }

  .v-application .mr-sm-10 {
    margin-right: 5px !important;
  }
}

</style>
